import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Button, Col, Input, message, Row, Form } from 'antd';
import { errorMessageHandler } from 'Base/Errors/components/ErrorHandler';
import { inject, observer } from 'mobx-react';
import React from 'react';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const AuthUser = props => {
  const [isLoading, setLoading] = React.useState(false);

  async function onFinish(values) {
    setLoading(true);
    const { userName, password } = values;
    const { response, error } = await props.sessionStore.login(userName, password);
    if (response) {
      message.success('Logged in Successfully!');
    } else {
      message.error(errorMessageHandler(error));
    }
    setLoading(false);
  }
  return (
    <Row type="flex" align="middle" style={{ paddingTop: '15vh' }}>
      <Col span={12} offset={6}>
        <div className="login-form">
          <h1>Welcome to Alex</h1>
          <Form
            initialValues={{
              userName: '',
              password: '',
            }}
            onFinish={onFinish}
            {...layout}
            layout="vertical"
            name="login"
          >
            <Form.Item
              label="Username"
              name="userName"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input
                placeholder="Enter unique Username"
                prefix={<UserOutlined style={{ color: '#9b9b9b' }} />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Password"
                prefix={<LockOutlined style={{ color: '#9b9b9b' }} />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={isLoading}
                htmlType="submit"
                style={{ margin: '8px 0' }}
                type="primary"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
export default inject('sessionStore')(observer(AuthUser));

import React from 'react';
import * as Sentry from '@sentry/react';
// import { showReportDialog } from '@sentry/browser';
import { Result, Button } from 'antd';
import { BugFilled } from '@ant-design/icons';

export default function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary fallback={props.fallback || <DefaultFallback />}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

function DefaultFallback() {
  return (
    <Result
      status="500"
      title="Sorry, Something went terribly wrong."
      subTitle="Don't worry our team has been notified and is working on solving the issue."
      extra={
        <>
          <Button icon={<BugFilled />} onClick={Sentry.showReportDialog}>
            Report Feedback
          </Button>
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </>
      }
    />
  );
}

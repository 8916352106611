import AuthUser from 'Auth/components/AuthUser';
import LoadingComponent from 'Base/components/LoadingComponent';
import { Session } from 'Base/stores/SessionStore';
import { inject, observer, Provider } from 'mobx-react';
import React, { Component, lazy, Suspense } from 'react';

const ApplicationLayoutPromise = import('Layout/ApplicationLayout');
const ApplicationLayout = lazy(() => ApplicationLayoutPromise);

@inject('sessionStore')
@observer
class BaseApp extends Component {
  render() {
    const { authenticated } = this.props.sessionStore;

    if (authenticated === undefined) {
      return <LoadingComponent />;
    }
    if (!authenticated) {
      return <AuthUser />;
    }

    return (
      <Suspense fallback={<LoadingComponent />}>
        <ApplicationLayout />
      </Suspense>
    );
  }
}

function App() {
  const session = Session.create();
  return (
    <Provider sessionStore={session}>
      <BaseApp />
    </Provider>
  );
}

export default App;

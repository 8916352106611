import { resetAxiosDefaults, setAxiosDefaults } from 'Auth/AxiosDefault';
import axios from 'axios';
import { startCase as _startCase } from 'lodash-es';
import { flow, types } from 'mobx-state-tree';
import { setSentryScope, initializeFreshdesk } from 'thirdPartyIntegrations';
import { Initials } from './InitialsStore';
import { ADMIN_GROUP, MANAGER_GROUP, OPERATOR_GROUP } from '../../constants';
import { message } from 'antd';

const _User = types
  .model({
    id: types.identifierNumber,
    email: types.string,
    username: types.string,
    first_name: types.string,
    last_name: types.string,
    is_active: types.boolean,
    locale: types.maybeNull(types.string),
    group: types.frozen(), //TODO: Later, might need a model if there are any operations on this
  })
  .preProcessSnapshot(incoming => {
    if (!incoming) return;
    const { first_name, last_name, settings, ...rest } = incoming;
    return {
      ...rest,
      first_name: _startCase(first_name),
      last_name: _startCase(last_name),
      locale: settings?.locale ?? null,
    };
  })
  .actions(self => ({
    updateUserLocale: flow(function* updateUserLocale(locale) {
      try {
        yield axios({
          method: 'put',
          baseURL: process.env.REACT_APP_DOC_MANAGER_URL,
          url: `/user/${self.id}/update`,
          data: {
            settings: {
              locale,
            },
          },
        });

        self.locale = locale;
      } catch (e) {
        console.error('Could not save user settings', e);
        message.error('Could not save user settings');
      }
    }),
  }))
  .views(self => ({
    get isAdmin() {
      return self.group.id === ADMIN_GROUP.id;
    },
    get isManager() {
      return self.group.id === MANAGER_GROUP.id;
    },
    get isOperator() {
      return self.group.id === OPERATOR_GROUP.id;
    },
    get group_id() {
      return self.group.id;
    },
    get name() {
      return [self.first_name, self.last_name].filter(Boolean).join(' ');
    },
    get userLocale() {
      return self.locale ?? 'default';
    },
  }));

export const User = types.compose(Initials, _User).named('User');

export const Session = types
  .model('Session', {
    user: types.maybe(User),
    expires_in: types.maybe(types.string),
    token: types.maybe(types.string),
    authenticated: types.maybe(types.boolean),
  })
  .volatile(_ => ({
    move_to_next_job: true,
  }))
  .actions(self => ({
    afterCreate() {
      self.validate_token();
    },
    login: flow(function* login(username, password) {
      return yield self.login_validate({
        method: 'post',
        path: 'login',
        data: { username, password },
      });
    }),
    validate_token() {
      const storedToken = localStorage.getItem('user_token');
      if (!storedToken) {
        self.clear();
        return;
      }
      self.login_validate({ method: 'get', path: 'get', storedToken });
    },
    login_validate: flow(function* login_validate({ method, path, data, storedToken }) {
      try {
        const response = yield axios({
          method,
          baseURL: process.env.REACT_APP_DOC_MANAGER_URL,
          url: `/user/${path}`,
          data,
          headers: {
            Authorization: storedToken ? `Token ${storedToken}` : undefined,
            'Content-Type': 'application/json',
          },
        });
        self.initialize(response.data.data);
        return { response: response.data.data };
      } catch (error) {
        console.error(error);
        self.clear();
        return { error: error };
      }
    }),
    initialize({ user, expires_in, token }) {
      localStorage.setItem('user_token', token);
      user.group = user.groups[0];
      self.user = user;
      self.expires_in = expires_in;
      self.token = token;
      self.authenticated = true;
      setAxiosDefaults(token, self.clear);
      initializeFreshdesk(user);
      setSentryScope(user);
      //
      // this.fetchQueues();
    },
    logout() {
      // try {
      //   axios({
      //     method: 'get',
      //     url: `/user/logout`,
      //   });
      // } catch (errors) {
      //   console.error(errors);
      // }
      self.clear();
    },
    clear() {
      localStorage.removeItem('user_token');
      self.user = undefined;
      self.expires_in = undefined;
      self.token = undefined;
      self.authenticated = false;
      resetAxiosDefaults();
      // TODO: reset hubspot, sentry
    },
  }));

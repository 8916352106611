import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';

import ErrorBoundary from 'Base/Errors/components/ErrorBoundary';
import { initializeSentry } from './thirdPartyIntegrations';
import './main.less';
import './main.scss';
import App from './App';
import 'mobx-react/batchingForReactDom';

initializeSentry();

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

export function initializeSentry() {
  //TODO: Add release ?
  if (process.env.IN_PRODUCTION) {
    Sentry.init({
      dsn: 'https://4669da451f5944a3a96a46513e4439b8@o264269.ingest.sentry.io/1469603',
      environment: process.env.REAL_ENV,
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: 0.2,
    });
  }
}

export function setSentryScope(user) {
  if (process.env.IN_PRODUCTION) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: user.email, id: user.id, username: user.username });
    });
  }
}

export function openSupport() {
  if (window.fcWidget && window.fcWidget.isLoaded()) {
    try {
      !window.fcWidget.isOpen() ? openFreshChat() : closeFreshChat();
    } catch (e) {
      window.location.href = 'mailto:alex.support@shipmnts.com';
    }
  } else {
    window.location.href = 'mailto:alex.support@shipmnts.com';
  }
}

function initFreshChat(user) {
  window.fcWidget.init({
    token: '5984d6ae-2a3c-492c-b722-681dedc5dcb6',
    host: 'https://wchat.in.freshchat.com',
    siteId: 'alex',
    externalId: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
  });

  closeFreshChat();
  window.fcWidget.on('widget:closed', window.fcWidget.hide);

  window.fcWidget.on('unreadCount:notify', resp => {
    if (resp.count > 0) openFreshChat();
  });
}

function initialize(i, t, user) {
  var e;
  // eslint-disable-next-line no-unused-expressions
  i.getElementById(t)
    ? initFreshChat(user)
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://wchat.in.freshchat.com/js/widget.js'),
      (e.onload = () => {
        initFreshChat(user);
      }),
      i.head.appendChild(e));
}

export function initializeFreshdesk(user) {
  initialize(document, 'Freshdesk Messaging-js-sdk', user);
}

function openFreshChat() {
  window.fcWidget.show();
  window.fcWidget.open();
}

function closeFreshChat() {
  window.fcWidget.hide();
  window.fcWidget.close();
}
